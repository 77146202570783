import { Dialog, TextField } from "@mui/material";
import { axiosRequestPromise } from "Apis";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BASE_PATH } from "utils";

const CreateBlogCategoryDialogue = ({ open, handleClose, state = "create", refetch, editingCategory }) => {
  const [formDataParams, setFormDataParams] = useState({
    ...editingCategory,
    name: editingCategory?.name || "",
    slug: editingCategory?.slug || "",
    description: editingCategory?.description || "",
  });
  const handleSubmit = () => {
    if (state === "edit") {
      delete formDataParams.image;
      toast.promise(axiosRequestPromise({ method: "put", url: `${BASE_PATH}/blogs/categories/${formDataParams._id}`, body: formDataParams }), {
        success: () => {
          refetch && refetch();
          handleClose();
          return "Category updated successfully";
        },
        loading: "Updating the category",
        error: () => {
          handleClose();
          return "Something went wrong";
        },
      });
    }
    if (state === "create") {
      toast.promise(axiosRequestPromise({ method: "post", url: `${BASE_PATH}/blogs/categories`, body: formDataParams }), {
        success: () => {
          refetch && refetch();
          handleClose();
          return "Category created successfully";
        },
        loading: "Creating the category",
        error: () => {
          handleClose();
          return "Something went wrong";
        },
      });
    }
  };
  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <MDBox mx={3} my={3}>
        <MDTypography sx={{ fontSize: 20, fontWeight: "500", color: "#292929" }}>{state === "edit" ? "Edit Category" : "Create Category"}</MDTypography>

        <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
          Category Name
        </MDTypography>
        <TextField
          placeholder="Category Name"
          InputLabelProps={{
            style: { color: "black" },
          }}
          value={formDataParams?.name}
          onChange={(e) =>
            setFormDataParams({
              ...formDataParams,
              name: e.target.value,
            })
          }
          variant="outlined"
          style={{ marginRight: 30, borderRadius: 20, marginTop: 2.5, width: 500 }}
          id="input-with-icon-textfield"
          label=""
        />
        <MDTypography sx={{ fontSize: 12, fontWeight: "400" }}>The name of the category</MDTypography>

        <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
          Category Slug
        </MDTypography>
        <TextField
          value={formDataParams?.slug}
          placeholder="Category Slug"
          InputLabelProps={{
            style: { color: "black" },
          }}
          onChange={(e) =>
            setFormDataParams({
              ...formDataParams,
              slug: e.target.value,
            })
          }
          variant="outlined"
          style={{ marginRight: 30, borderRadius: 20, marginTop: 2.5, width: 500 }}
          id="input-with-icon-textfield"
          label=""
        />
        <MDTypography sx={{ fontSize: 12, fontWeight: "400" }}>
          Letters, numbers, dash only. The slug i.e https://www.uptechunt.com/blog/category/this_part. The must be unique (Two category can’t share the same slug).
        </MDTypography>

        <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
          Description
        </MDTypography>
        <TextField
          placeholder="Description"
          multiline
          rows={5}
          value={formDataParams?.description}
          InputLabelProps={{
            style: { color: "black" },
          }}
          onChange={(e) =>
            setFormDataParams({
              ...formDataParams,
              description: e.target.value,
            })
          }
          variant="outlined"
          style={{ marginRight: 30, borderRadius: 20, marginTop: 2.5, width: 500 }}
          id="input-with-icon-textfield"
          label=""
        />

        <MDBox mx={0} mt={2} sx={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
          <MDBox
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F4F4F4", borderRadius: 10, border: "1px solid #A9A8A8", cursor: "pointer" }}
            onClick={handleClose}
          >
            <MDTypography px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#1E0707" }}>
              Cancel
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#227BE9", borderRadius: 10, border: "1px solid #A9A8A8", marginLeft: 2, cursor: "pointer" }}
            onClick={handleSubmit}
          >
            <MDTypography px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#FFFFFF" }}>
              Save Changes
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Dialog>
  );
};

export default CreateBlogCategoryDialogue;
