import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import search from "../../../assets/images/search.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { DeleteBlogCategory } from "Apis/Apis";
import TableWithPagination from "./data/TableWithPagination";
import CreateBlogCategoryDialogue from "./CreateBlogCategoryDialogue";
import { axiosRequestPromise } from "Apis";
import { BASE_PATH } from "utils";
import usePagination from "hooks/usePagination";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const rowsPerPageOptions = [5, 10, 25];
const BlogCategory = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [opencategory, setOpencategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const { page, limit, setPage, setLimit } = usePagination(rowsPerPageOptions);

  const [blogCategoryList, setBlogCategoryList] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const updateCategoryList = async () => {
    setLoading(true);
    const cats = await axiosRequestPromise({ method: "get", url: `${BASE_PATH}/blogs/categories?limit=${limit}&offset=${page * limit}` });
    setBlogCategoryList(cats.data.data.docs);
    setResponseData(cats.data.data);
    setLoading(false);
  };

  useEffect(() => {
    updateCategoryList();
  }, [page, limit]);

  const handleInputChange = (event) => {
    navigate(`/blogs/category?q=${event.target.value}`);
  };

  const deleteCategory = async (id) => {
    const res = await DeleteBlogCategory(id);
    alert(JSON.stringify(res));
    updateCategoryList();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {opencategory && <CreateBlogCategoryDialogue handleClose={() => setOpencategory(false)} open={opencategory} state="create" refetch={updateCategoryList} />}

      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
            Are you sure you want to delete blog?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
            <MDButton type="submit" variant="gradient" color="error" onClick={deleteCategory} sx={{ mr: 2 }}>
              delete
            </MDButton>
            <MDButton type="submit" color="secondary" sx={{ mr: 2 }} onClick={() => setOpen(false)}>
              cancel
            </MDButton>
          </Typography>
        </Box>
      </Modal>
      <MDBox pt={"3px"} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={2} display="flex" justifyContent="space-between" alignItems="center" mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography color="white" sx={{ fontSize: "20px", fontWeight: "600" }}>
                  All Categories {blogCategoryList.length}
                </MDTypography>
                <MDButton onClick={() => setOpencategory(true)} variant="gradient" color="dark">
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon> Category
                </MDButton>
              </MDBox>
              <MDBox mt={1} mx={3}>
                <TextField
                  placeholder="Search Blog Category"
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  onChange={handleInputChange}
                  className="TextField-without-border-radius"
                  variant="outlined"
                  style={{ marginRight: 30, marginTop: 10, width: "40%" }}
                  id="input-with-icon-textfield"
                  label=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={search} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <img src={"/images/icons/send.png"} style={{ width: "25px", height: "25px" }} />
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              </MDBox>
              <div>
                <TableWithPagination
                  handleChangePage={(e, p) => setPage(p)}
                  handleChangeRowsPerPage={(e) => setLimit(e.target.value)}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={rowsPerPageOptions}
                  searchParams={searchParams}
                  setBlogCategoryList={setBlogCategoryList}
                  blogCategoryList={blogCategoryList}
                  responseData={responseData}
                  setResponseData={setResponseData}
                  loading={loading}
                  setLoading={setLoading}
                  refetch={updateCategoryList}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default BlogCategory;
