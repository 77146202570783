import {
  BsFileZip,
  BsFiletypeCss,
  BsFiletypeCsv,
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeExe,
  BsFiletypeGif,
  BsFiletypeHtml,
  BsFiletypeJava,
  BsFiletypeJpg,
  BsFiletypeJs,
  BsFiletypeJson,
  BsFiletypeJsx,
  BsFiletypeMov,
  BsFiletypeMp3,
  BsFiletypeMp4,
  BsFiletypePdf,
  BsFiletypePng,
  BsFiletypePy,
  BsFiletypeSvg,
  BsFiletypeTsx,
  BsFiletypeWav,
  BsFiletypeXlsx,
} from "react-icons/bs";

export const SLUG_REGEX = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
export const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const LOGGED_IN_USER_ROLE = localStorage.getItem("@role");
export const LOGGED_IN_USER_ID = localStorage.getItem("@my_user_id");
export const ROLE_SUPER_ADMIN = "super_admin";
export const ROLE_ADMIN = "admin";
export const ROLE_EDITOR = "editor";
export const publishList = [
  {
    label: "Published",
    value: "published",
    disabled: LOGGED_IN_USER_ROLE === ROLE_SUPER_ADMIN || LOGGED_IN_USER_ROLE === ROLE_ADMIN ? false : true,
  },
  {
    label: "Not Published",
    value: "not published",
    disabled: LOGGED_IN_USER_ROLE === ROLE_SUPER_ADMIN || LOGGED_IN_USER_ROLE === ROLE_ADMIN ? false : true,
  },
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export const flattenArray = (arr = []) => {
  const result = [];

  function traverse(obj, level) {
    obj.level = level;
    result.push(obj);
    if (obj.children && obj.children.length > 0) {
      for (const child of obj.children) {
        traverse(child, level + 1);
      }
    }
  }

  for (const obj of arr) {
    traverse(obj, 0);
  }

  return !!result?.length ? result : [];
};
// DO NOT REMOVE THIS
// function rearrangeArray(arr) {
//   const map = new Map();

//   const result = [];
//   for (const obj of arr) {
//     obj.children = [];
//     map.set(obj._id, obj);
//   }

//   for (const obj of arr) {
//     const parentId = obj.parent;
//     if (parentId) {
//       const parentObj = map.get(parentId);
//       if (parentObj) {
//         parentObj.children.push(obj);
//       }
//     } else {
//       result.push(obj);
//     }
//   }

//   return result;
// }
export const getDeepLevel = (maxLevel) => Array.from({ length: maxLevel }, (_, index) => <div key={index + 1}> </div>);

export const FILE_TYPES = [
  {
    mimetype: "image/png",
    icon: <BsFiletypePng fontSize={25} />,
  },
  {
    mimetype: "image/jpeg",
    icon: <BsFiletypeJpg fontSize={25} />,
  },
  {
    mimetype: "image/gif",
    icon: <BsFiletypeGif fontSize={25} />,
  },
  {
    mimetype: "application/pdf",
    icon: <BsFiletypePdf fontSize={25} />,
  },
  {
    mimetype: "application/doc",
    icon: <BsFiletypeDoc fontSize={25} />,
  },
  {
    mimetype: "image/svg+xml",
    icon: <BsFiletypeSvg fontSize={25} />,
  },
  {
    mimetype: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: <BsFiletypeXlsx fontSize={25} />,
  },
  {
    mimetype: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: <BsFiletypeXlsx fontSize={25} />,
  },
  {
    mimetype: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    icon: <BsFiletypeDocx fontSize={25} />,
  },
  {
    mimetype: "video/mp4",
    icon: <BsFiletypeMp4 fontSize={25} />,
  },
  {
    mimetype: "video/quicktime",
    icon: <BsFiletypeMov fontSize={25} />,
  },
  {
    mimetype: "application/zip",
    icon: <BsFileZip fontSize={25} />,
  },
  {
    mimetype: "application/x-msdownload",
    icon: <BsFiletypeExe fontSize={25} />,
  },
  {
    mimetype: "application/json",
    icon: <BsFiletypeJson fontSize={25} />,
  },
  {
    mimetype: "application/javascript",
    icon: <BsFiletypeJs fontSize={25} />,
  },
  {
    mimetype: "text/jsx",
    icon: <BsFiletypeJsx fontSize={25} />,
  },
  {
    mimetype: "text/javascript",
    icon: <BsFiletypeJsx fontSize={25} />,
  },
  {
    mimetype: "text/tsx",
    icon: <BsFiletypeTsx fontSize={25} />,
  },
  {
    mimetype: "application/x-python",
    icon: <BsFiletypePy fontSize={25} />,
  },
  {
    mimetype: "text/x-java-source",
    icon: <BsFiletypeJava fontSize={25} />,
  },
  {
    mimetype: "text/html",
    icon: <BsFiletypeHtml fontSize={25} />,
  },
  {
    mimetype: "text/css",
    icon: <BsFiletypeCss fontSize={25} />,
  },
  {
    mimetype: "text/csv",
    icon: <BsFiletypeCsv fontSize={25} />,
  },
  {
    mimetype: "audio/mpeg",
    icon: <BsFiletypeMp3 fontSize={25} />,
  },
  {
    mimetype: "audio/wav",
    icon: <BsFiletypeWav fontSize={25} />,
  },
];

export function stripHtml(htmlString) {
  // Create a temporary DOM element
  const tempDiv = document.createElement("div");

  // Set the innerHTML of the temporary element to the HTML string
  tempDiv.innerHTML = htmlString;

  // Extract and return the text content from the temporary element
  return tempDiv.textContent || tempDiv.innerText || "";
}
