import axios from "axios";
import { BASE_PATH } from "utils";
const URL = BASE_PATH;

export async function LoginUser(value) {
  try {
    const response = await axios.post(URL + "/auth/login", value);
    localStorage.setItem("@storage_Key", response.data.data.access_token);
    localStorage.setItem("@email", value?.email);
    localStorage.setItem("@password", value?.password);
    return response.data;
  } catch {
    null;
  }
}

const getData = async () => {
  try {
    const value = localStorage.getItem("@storage_Key");
    if (value !== null) {
      return value;
    }
  } catch (e) {
    return e.response.data;
  }
};

export async function AuthMe() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/users/me/`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function UsersList(search) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/users${search ? `?search=${search}` : ""}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function UsersListWithPageLimit() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/users?limit=49`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function ProfileListgetSearch(search, status) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/profiles?type=${status}&search=${search}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function ProfileListget() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/profiles`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function PostUser(formdata) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(
        URL + "/users",
        {
          first_name: formdata.first_name,
          last_name: formdata.last_name,
          email: formdata.email,
          password: formdata.password,
          role: formdata.role,
          send_email_notification: formdata.send_email_notification,
        },
        config
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function UsersListSearch(search) {
  var myHeaders = new Headers();
  return getData().then(async (e) => {
    myHeaders.append("Authorization", `Bearer ${e}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(`https://lobster-app-i483n.ondigitalocean.app/users/?search=${search}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });
  });
}

export async function SkillsListSearch(search) {
  var myHeaders = new Headers();
  return getData().then(async (e) => {
    myHeaders.append("Authorization", `Bearer ${e}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(`https://api-dev.uptechunt.com/skills?search=${search}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });
  });
}

export async function SingleUser(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/users/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function SingleProfile(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/profiles/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response?.data;
    } catch (e) {
      return e.response?.data;
    }
  });
}

export async function SkillsListWithPageLimit(pageLimit) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/skills?limit=${pageLimit}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function UpdateUser(id, formdata) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.put(
        URL + `/users/${id}`,
        {
          first_name: formdata.first_name,
          last_name: formdata.last_name,
          email: formdata.email,
          password: formdata.password,
          role: formdata.role,
        },
        config
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function SingleSkill(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/skills/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function ForgotPasswordApi(email) {
  try {
    const response = await axios.post(URL + "/auth/forgot-password", {
      email: email,
    });
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function UpdateSkill(id, name, description) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.put(
        URL + `/skills/${id}`,
        {
          name: name,
          description: description,
        },
        config
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function UpdateCompleteSkill(id, name, description, parent) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.put(
        URL + `/skills/${id}`,
        {
          name: name,
          description: description,
          parent: parent,
        },
        config
      );
      return response;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function DeleteCategory(id) {
  return new Promise(async (resolve, reject) => {
    const auth = await getData();
    const config = {
      headers: { Authorization: `Bearer${" "}${auth}` },
    };
    try {
      const response = await axios.delete(URL + `/blogs/categories/${id}`, config);
      return resolve(response.data);
    } catch (e) {
      return reject(e.response.data);
    }
  });
}

export async function DashboardRequest() {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.get(URL + `/reports/dashboard`, config);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function PostsList() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/posts`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function PostPosts(formdata) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(
        URL + "/posts",
        {
          country: formdata.country,
          state: formdata.state,
          city: formdata.city,
          title: formdata.title,
          body: formdata.body,
          description: formdata.description,
          category: formdata.parent_category,
          image: formdata.image,
          slug: formdata.slug,
          faq: formdata?.FAQs,

          metadata: {
            seo_meta: {
              title: formdata.seo_title,
              description: formdata.seo_description,

              canonical: formdata.seo_canonical,
              keywords: formdata.seo_keywords,
              robots: formdata.seo_robots,
            },
            opengraph: {
              title: formdata.ogtitle,
              description: formdata.ogdescription,
              card: formdata.ogcard,
              site_name: formdata.ogsite_name,
              url: formdata.ogurl,
              image: formdata.ogimage,
            },
            twitter: {
              title: formdata.twittertitle,
              description: formdata.twitterdescription,
              card: formdata.twittercard,
              site_name: formdata.twittersite_name,
              url: formdata.twitterurl,
              image: formdata.twitterimage,
            },
          },
        },
        config
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function SinglePost(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/posts/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function UpdatePost(id, formdata) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.put(
        URL + `/posts/${id}`,
        {
          title: formdata?.title,
          body: formdata?.body,
          category: formdata?.parent_category,
          // image: formdata?.image,
          slug: formdata?.slug,
          faq: formdata?.faq,
          metadata: {
            seo_meta: {
              title: formdata?.seo_title,
              description: formdata?.seo_description,

              canonical: formdata?.seo_canonical,
              keywords: formdata?.seo_keywords,
              robots: formdata?.seo_robots,
            },
            opengraph: {
              title: formdata?.ogtitle,
              description: formdata?.ogdescription,
              card: formdata?.ogcard,
              site_name: formdata?.ogsite_name,
              url: formdata?.ogurl,
              image: formdata?.ogimage,
            },
            twitter: {
              title: formdata?.twittertitle,
              description: formdata?.twitterdescription,
              card: formdata?.twittercard,
              site_name: formdata?.twittersite_name,
              url: formdata?.twitterurl,
              image: formdata?.twitterimage,
            },
          },
        },
        config
      );
      return response?.data;
    } catch (e) {
      return e.response?.data || e;
    }
  });
}

export async function DeletePost(id) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.delete(URL + `/posts/${id}`, config);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function PostSlug(slug) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(
        URL + "/posts/check-slug-availability",
        {
          slug: slug,
        },
        config
      );
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function BlogsList(page, offset) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let url = `${URL}/blogs`;
    if (page) {
      url += `?page=${page}`;
    }
    if (offset) {
      url += `?rows=${offset}`;
    }

    let reqOptions = {
      url,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function PostBlogs(new_data) {
  return new Promise(async (resolve, reject) => {
    const e = await getData();
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(URL + "/blogs", new_data, config);
      resolve(response.data);
    } catch (e) {
      reject(e.response.data);
    }
  });
}

export async function SingleBlog(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/blogs/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function UpdateBlog(id, formdata) {
  return new Promise(async (resolve, reject) => {
    const auth = await getData();
    const config = {
      headers: { Authorization: `Bearer${" "}${auth}` },
    };
    try {
      const response = await axios.put(URL + `/blogs/${id}`, formdata, config);
      return resolve(response.data);
    } catch (e) {
      return reject(e.response.data);
    }
  });
}

export async function DeleteBlog(id) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.delete(URL + `/blogs/${id}`, config);
      // await localStorage.setItem('@storage_Key', response.data.token)
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function BlogSlug(slug) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(
        URL + "/posts/check-slug-availability",
        {
          slug: slug,
        },
        config
      );
      // await localStorage.setItem('@storage_Key', response.data.token)
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function postCategoriesList() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/posts/categories`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function PostCategoriesList() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/posts/categories`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function PostCategoriesSingle(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/posts/categories/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function DeletePostCategory(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/posts/categories/${id}`,
      method: "DELETE",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function BlogsCategoryList() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/blogs/categories`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function PostBlogsCategory(formdata) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(
        URL + "/blogs/categories",
        {
          name: formdata.name,
          slug: formdata.slug,
          description: formdata.description,
        },
        config
      );
      // await localStorage.setItem('@storage_Key', response.data.token)
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function SingleBlogCategory(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/blogs/categories/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function UpdateBlogCategory(id, formdata) {
  return new Promise(async (resolve, reject) => {
    const auth = await getData();
    const config = {
      headers: { Authorization: `Bearer${" "}${auth}` },
    };
    try {
      const response = await axios.put(
        URL + `/blogs/categories/${id}`,
        {
          name: formdata.name,
          slug: formdata.slug,
          description: formdata.description,
        },
        config
      );
      // await localStorage.setItem('@storage_Key', response.data.token)
      return resolve(response.data);
    } catch (e) {
      reject(e.response.data);
    }
  });
}

export async function DeleteBlogCategory(id) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.delete(URL + `/blogs/categories/${id}`, config);
      // await localStorage.setItem('@storage_Key', response.data.token)
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function FileUpload(formdata) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(
        URL + "/blogs/categories",
        {
          file: formdata.name,
          slug: formdata.slug,
        },
        config
      );
      // await localStorage.setItem('@storage_Key', response.data.token)
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function CheckResetCode(email, code) {
  try {
    const response = await axios.get(URL + `/auth/forgot-password/reset/check?code=${code}&email=${email}`);
    return response.data;
  } catch {
    null;
  }
}

export async function UpdatePassword(email, code, password) {
  try {
    const response = await axios.put(URL + `/auth/forgot-password/reset`, {
      email,
      code,
      password,
    });
    return response.data;
  } catch {
    null;
  }
}

export async function GetCountries() {
  try {
    const response = await axios.get(URL + `/locations`);
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
}

export async function GetStates(c_code) {
  try {
    const response = await axios.get(URL + `/locations?country_code=${c_code}`);
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
}

export async function GetCities(c_code, state_code) {
  try {
    const response = await axios.get(URL + `/locations?country_code=${c_code}&state_code=${state_code}`);
    return response?.data;
  } catch (e) {
    e?.response?.data;
  }
}

export async function UploadFileRequest(file) {
  return getData().then(async (e) => {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer${" "}${e}`,
      },
    };
    try {
      const response = await axios.post(URL + "/files", formData, config);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function BanUserRequest(id) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(`${URL}/users/${id}/ban`, { reason: "Not Applicable" }, config);
      return response.data;
    } catch (e) {
      return new Error("Could't ban user");
    }
  });
}

export async function UnbanUserRequest(id) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(`${URL}/users/${id}/unban`, { reason: "Not Applicable" }, config);
      return response.data;
    } catch (e) {
      return new Error("Could't unban user");
    }
  });
}

export async function VerifyEmailRequest(id) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.post(`${URL}/users/${id}/VerifyEmail`, { reason: "Not Applicable" }, config);
      return response.data;
    } catch (e) {
      return new Error("Could't unban user");
    }
  });
}

export async function CreateNewCategory(formData) {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };

    const response = await axios.post(`${URL}/posts/categories`, { name: formData?.name, slug: formData?.slug, description: formData?.description }, config);

    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function GetTables(search, currentPage, limit) {
  let offset = 0,
    srch = "";
  if (currentPage && limit) {
    offset = (currentPage - 1) * +limit;
  }
  if (search) {
    srch = search;
  }
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.get(URL + `/tables?offset=${offset || 0}&limit=${limit || 10}${srch && `&search=${srch}`}`, config);
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
}

export async function GetTablesById(id) {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.get(URL + `/tables/${id}`, config);
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
}

export async function DeleteTable(id) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.delete(URL + `/tables/${id}`, config);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}
export async function UpdateTable(id, data) {
  return getData().then(async (e) => {
    const config = {
      headers: { Authorization: `Bearer${" "}${e}` },
    };
    try {
      const response = await axios.put(URL + `/tables/${id}`, data, config);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function CreateNewTableRequest(formData) {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.post(`${URL}/tables`, formData, config);
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
}

export async function ChangePasswordRequest(formData) {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.put(
      URL + `/auth/change-password`,
      {
        password: formData?.password,
        new_password: formData?.new_password,
      },
      config
    );
    return response?.data;
  } catch (e) {
    return e?.response?.data;
  }
}

export async function GetAllJobsRequest() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/jobs`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function GetSingleJobRequest(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/jobs/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function RemoveSingleJobRequest(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/jobs/${id}`,
      method: "DELETE",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function GetMainCategories() {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/skills`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function GetSingleFile(id) {
  return getData().then(async (e) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${e}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${URL}/files/${id}`,
      method: "GET",
      headers: headersList,
    };

    try {
      let response = await axios.request(reqOptions);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  });
}

export async function updatePostCategory(id, formData) {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };

    const response = await axios.put(`${URL}/posts/categories/${id}`, { name: formData?.name, slug: formData?.slug, description: formData?.description }, config);

    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function getAllImages() {
  try {
    const e = await getData();
    const config = {
      headers: {
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.get(`${URL}/blogs/images`, config);
    return response?.data?.data?.docs;
  } catch (e) {
    return e.response.data;
  }
}

export async function createUrl(data) {
  try {
    const e = await getData();
    const config = {
      headers: {
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.post(`${URL}/blogs/images`, data, config);
    return response?.success;
  } catch (e) {
    return false;
  }
}

export const CreateSuccessStoryCategory = async (formData) => {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.post(`${URL}/stories/categories`, formData, config);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};
export const updateSuccessStoryCategory = async (id, formData) => {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.put(`${URL}/stories/categories/${id}`, formData, config);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};
export const removeSuccessStoryCategory = async (id) => {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.delete(`${URL}/stories/categories/${id}`, config);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

export const removeUser = async (id) => {
  try {
    const e = await getData();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${e}`,
      },
    };
    const response = await axios.delete(`${URL}/auth/profile/${id}`, config);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};
